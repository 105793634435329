import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Typography = makeShortcode("Typography");
const SingleImage = makeShortcode("SingleImage");
const GoTo = makeShortcode("GoTo");
const ButtonDialog = makeShortcode("ButtonDialog");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "supporting-your-teen-as-restrictions-ease"
    }}>{`Supporting your teen as restrictions ease`}</h2>
    <p>{`The uncertainty brought about by the pandemic can have a lasting impact on your teen. They may need your ongoing emotional support, even many months after local restrictions have eased.`}</p>
    <p>{`Young people may continue to have worries about the pandemic even as the situation improves. They may struggle to adjust
to the changes and their anxiety might even `}<i>{`increase`}</i>{`.`}</p>
    <p>{`For example if a teen says they don’t want to go school, consider whether there might be more below the surface. If this happens for your teen, understanding `}<i>{`why`}</i>{` they are struggling will
help you and them to work through the challenge together.`}</p>
    <Typography variant="h4" color="primary" mdxType="Typography">
  <b>Uncovering the ‘root causes’ of their struggle to adjust</b>
    </Typography>
    <SingleImage gridSize={12} src={'/images/m2/19-tree-with-text.png'} mdxType="SingleImage" />
    <p>{`If your teen is struggling to adjust to the easing of restrictions, try doing this activity with them. See if you can uncover the root causes of their struggle.`}</p>
    <Typography variant="h4" color="primary" mdxType="Typography">
  <b>How can you help your teen to adjust to the changes?</b>
    </Typography>
    <p>{`The best place to start is by having a chat with your teen about what’s on their mind. Use the tips outlined earlier in this module when talking with your teen about the pandemic. If you haven’t already, you might also want to check out the `}<GoTo to="/dashboard" mdxType="GoTo"><i>{`Connect`}</i></GoTo>{` module.`}</p>
    <p>{`Encourage your teen to return to their usual activities (e.g. sports, music, social activities) when they are allowed to. Help your teen to think realistically about any possible risks, and problem-solve how they will manage these.`}</p>
    <p>{`Show appreciation for your teen’s efforts to adjust to the challenges of the pandemic, however big or small.`}</p>
    <Typography variant="h4" color="primary" mdxType="Typography">
  <b>Is your teen worried about contracting COVID-19?</b>
    </Typography>
    <p>{`If your teen is anxious about getting infected, work with them to brainstorm what will help them feel safer. Remember to express empathy for their fears, even if you don’t share the concern. It might also help to reassure your teen that you will make sure they receive medical care if they need it.`}</p>
    <ButtonDialog linkText="Does your teen have a disability, learning difficulty, or chronic health condition?" mdxType="ButtonDialog">
  <ul>
    <li>Keep in mind that they may be more likely to worry about getting infected or becoming sicker if infected.</li>
    <li>
      Consult your care-provider to discuss alternative arrangements for any in-person supports your teen usually has,
      as needed. If there are no alternative options, acknowledge to your teen that they may have added difficulties,
      discomfort, or pain as a result.
    </li>
    <li>
      Reach out to professionals and other families in similar situations to work out how to address any extra
      challenges during the pandemic (e.g. discomfort and/or sensory, communication, or movement challenges)
    </li>
    <li>Have a plan for the care of your teen that others can follow if you become ill.</li>
  </ul>
    </ButtonDialog>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      